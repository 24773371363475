import rings from './assets/Jewel/rings.jpg'
import bag from './assets/bags/bag.jpeg'
import perfume from './assets/perfume/perfume.webp'

const data = [
    {
        id:1,
        img:rings,
        description:'Adorn Your Beauty with Exquisite Handcrafted Jewelry: Timeless Elegance, Endless Charm.',
        link:'/jewelry'
    },
    {
        id:2,
        img:bag,
        description:'Discover Stylish Bags for Every Occasion: Fashion-forward Designs, Unmatched Quality, and Versatile Choices to Complement Your Unique Style. Elevate Your Look with Our Trendy Bag Collection!',
        link:'/fashion'
    },
    {
        id:3,
        img:perfume,
        description:'Indulge Your Senses in Luxury Fragrances: Immerse Yourself in Captivating Scents, Crafted for Elegance and Long-lasting Allure. Find Your Signature Perfume and Unveil the Essence of Pure Glamour and Sophistication.',
        link:'/perfumes'
    }
]

export {data}