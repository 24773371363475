import React from 'react'
import Navbar from '../../components/navigation/Navbar'
import Hero from './Hero'
import Footer from '../../components/Footer'
import Jewel from './Jewel'

function Home() {
  return (
    <>
        <section className='flex flex-col h-screen'>
          <Navbar />
          <Hero />
        </section>
        <Jewel />
        <Footer />
    </>
  )
}

export default Home