import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter,Route,Routes, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Blogs from './pages/Blogs';
import ContactUs from './pages/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
      <Route index element={<App />} />
      <Route path="/" element={<App/>}/>
      <Route path='/contactus' element={<ContactUs/>}/>
      <Route path='/jewelry' element/>
      <Route path='/perfumes' element/>
      <Route path='/fashion' element/>
      <Route path='/blogs' element={<Blogs />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
