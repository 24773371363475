import React, { useState, useEffect } from 'react';
import image1 from '../../assets/image1.jpeg';
import image2 from '../../assets/image2.jpeg';
import image3 from '../../assets/image3.jpeg';
import { Link } from 'react-router-dom';

function Hero(){
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    image1,
    image2,
    image3,
    // Add more image URLs as needed
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="flex flex-1 items-center relative bg-amber-50 text-slate-950">
      <div className="absolute inset-0 opacity-60">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt=""
            className={`object-cover w-full h-full absolute transition-opacity duration-1000 ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
          />
        ))}
      </div>
      <div className="container mx-auto px-8 relative z-10">
        <div className="text-center flex flex-col items-center">
          <h1 className="sm:text-4xl text-2xl font-bold mb-4">Discover Exquisite Jewelry and Fashion</h1>
          <p className="sm:text-lg text-base mb-8 max-w-xl">
            Explore our collection of stunning jewelry crafted with precision and passion. Our Fragrances are made with the finest ingredients to give you a long lasting scent.
          </p>
          <button className="bg-slate-950 text-amber-50 py-2 px-6 hover:bg-slate-900">
            <Link to='/blogs'>Explore</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;