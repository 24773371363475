import React from 'react'
import { Link } from 'react-router-dom'
import {data} from '../../content'
import { motion} from "framer-motion"

const cardVariants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 10,
        rotate: 0,
        transition: {
        type: "spring",
        bounce: 0,
        duration: 1.8
        }
    }
};
function Jewel() {
    return (
        <>
        {data.map((copy,index)=>{ 
            const isEvenIndex = index % 2 === 0;
            return(
                <motion.div  initial="offscreen" whileInView="onscreen" variants={cardVariants} 
                    key={copy.id} className={`h-screen flex flex-col sm:flex-row justify-center items-center mx-5 ${isEvenIndex ? 'sm:flex-row-reverse' : ''}`}>
                    <img src={copy.img} alt='Rings' className='sm:h-4/5 h-1/2 sm:w-2/4 w-full'/>
                    <div className='text-center'>
                        <p className='sm:text-xl text-sm px-5 mt-4'>{copy.description}</p>
                        <button className="bg-slate-950 text-amber-50 py-2 px-6 hover:bg-slate-800 m-5">
                            <Link to={copy.link}>Explore</Link>
                        </button>
                    </div>
                </motion.div>
            )
        })}
        </>
    )
}

export default Jewel