import React from 'react'
import Navbar from '../components/navigation/Navbar'
import Footer from '../components/Footer'

function ContactUs() {
    return (
        <>
            <Navbar />
            <div className='flex flex-col h-screen items-center mt-10'>
                <h1 className='sm:text-3xl text-lg uppercase font-black text-slate-950'>
                    Contact Us
                </h1>
                <p className='sm:text-xl text-base text-slate-900 py-5 px-5'>
                    We are here to help and answer any question you might have. We look forward to hearing from you.
                </p>
                <div className='flex flex-col justify-center items-center'>
                    {/* <div className='flex flex-row justify-center items-center'>
                        <h1 className='sm:text-xl text-base uppercase font-black text-slate-950'>
                            Phone
                        </h1>
                        <p className='sm:text-xl text-base text-slate-900 p-5'>
                            +91 9910543574
                        </p>
                    </div> */}
                    <div className='flex flex-row justify-center items-center'>
                        <h1 className='sm:text-xl text-base uppercase font-black text-slate-950'>
                            Email
                        </h1>
                        <p className='sm:text-xl text-base text-slate-900 p-5'>
                            svarcisteam@gmail.com
                        </p>
                    </div>
                </div>                    
            </div>
            <Footer />
        </>
    )
}

export default ContactUs