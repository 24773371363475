import React, { useState } from 'react'
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaInstagram } from 'react-icons/fa';
import Logo from "../../assets/Svarcis.png"
import { Link } from 'react-router-dom';

function Navbar() {
    const [toggle, setToggle] = useState(false);
    const handleToggle = () => setToggle(!toggle);

    return (
        <div className='flex justify-between items-center px-7 py-6 w-full top-1'>
            {toggle &&
                (<nav className={`fixed inset-y-0 left-0 z-50 backdrop-blur-xl sm:w-2/5 w-1/2 flex flex-col justify-center items-center ${toggle ? 'open-menu' : ''}
                `}>
                    <ul className={`flex flex-col text-black  mx-8`}>
                        <li className='py-5'><a href="/">Home</a></li>
                        <li className='py-5'><Link to="/blogs">Blogs</Link></li>
                        <li className='py-5'><Link to="/about">About</Link></li>
                        <li className='py-5'><Link to="/contactus">Contact</Link></li>
                    </ul>
                </nav>)
            }
            <button onClick={() => handleToggle()} className="block z-50">
                {!toggle ? <AiOutlineMenu size={30} /> : <AiOutlineClose size={30}/>}
            </button>
            <a href='/'>
                <img src={Logo} alt='Logo' className='sm:h-24 sm:w-full h-9 w-40  z-30 block brightness-75'/>
            </a>
            <a href="https://www.instagram.com/svarcisindia/"
                className="inline"
                >
                {" "}
                <FaInstagram size={25} />{" "}
            </a>
        </div>
    )
}

export default Navbar