import React from 'react'
import Logo from "../assets/Svarcis logo.png";
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className=''>
        <footer className='w-full bottom-0 bg-slate-950'>
            <div className="mx-auto w-full max-w-7xl px-8">
                <div className="grid grid-cols-1 justify-center items-center gap-4 md:grid-cols-2">
                    <div className='flex justify-center items-center'><img src={Logo} alt='svarcis' className='h-12 w-25 mt-7 mx-9'/></div>
                    <div className="flex md:flex-row flex-col justify-center items-center mx-4 text-amber-50">
                        <Link to='/' className='hover:scale-125 m-5'>Home</Link>
                        <Link to='/contactus' className='m-5 hover:scale-125'>Contact Us</Link>
                        <Link to='/about' className='m-5 hover:scale-125'>About</Link>
                        <Link to='/blogs' className='m-5 hover:scale-125'>Blogs</Link>
                    </div>
                </div>
                <div className='mt-10 flex w-full flex-col justify-center border-t border-amber-50 py-4 md:flex-row md:justify-center'>
                    <div className='flex sm:flex-row flex-col justify-end items-center text-amber-50'>
                        <a href="https://www.instagram.com/svarcisindia/"
                        className="pr-8 m-2 inline"
                        >
                        {" "}
                        <FaInstagram size={20} />{" "}
                        </a>
                        <a href="https://www.facebook.com/svarcisluxuryboutique/"
                        className="pr-8 inline"
                        >
                        {" "}
                        <FaFacebookF size={20} />{" "}
                        </a>
                        <p>&copy; {currentYear} <a href='/' className=''>Svarcis India</a>. All Rights
                        Reserved. </p>
                    </div> 
                </div>
            </div>
        </footer>
        </div>
    )
}

export default Footer