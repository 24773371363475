import React from 'react'
import Navbar from '../components/navigation/Navbar'
import Footer from '../components/Footer'

function Blogs() {
  return (
    <>
        <Navbar />
        <div className='h-screen flex flex-col items-center justify-center'>
            <div className='text-2xl text-center w-1/2'>
                <p>Sparkle awaits! Stay tuned for our upcoming blog post revealing the latest trends, expert tips, and captivating stories from the world of jewels. </p>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Blogs